import React from 'react'
import styles from './ProductCard.module.sass'
import clsx from 'clsx'
import Link from 'next/link'
import { ProductBrandT } from '@/shared/types/ProductT'
import { useAuth } from '@/shared/providers/AuthProvider'

interface ProductCardDataT {
    id: number
    name: string
    name_ru?: ''
    is_active?: boolean
    is_deleted?: boolean
    price: {
        sale_value: number
    }
    old_name?: string
    main_image: {
        original_url: string
    } | null
    brand?: ProductBrandT
}

interface ProductCardProps {
    data: ProductCardDataT
    className?: string
    onClick?: () => void
}

export const ProductCard: React.FC<ProductCardProps> = ({ data, className, onClick }) => {
    const { favouriteProducts, addOrRemoveFromWishlist } = useAuth()

    const isFavourited = favouriteProducts.find((product) => product.id === data.id)

    const content = (
        <>
            <div className={styles.header_controls}>
                <button
                    className={clsx(styles.favourite_btn, isFavourited && styles.active)}
                    onClick={(e) => {
                        e.stopPropagation()
                        addOrRemoveFromWishlist(data.id)
                    }}
                >
                    <i className="icon-favourite"></i>
                </button>
            </div>
            <img
                loading="lazy"
                src={data.main_image && data.main_image ? data.main_image.original_url : '/assets/img/logo.svg'}
                width={500}
                height={500}
                alt={data.name}
                className={clsx(styles.poster, !data.main_image && styles.opacity)}
            />
            <div className={styles.content}>
                {data.brand ? (
                    <>
                        <div className={styles.title_wrap}>
                            <Link href={`/products?brandIds=${data.brand.id}`} className={styles.title}>
                                {data.brand.name}
                            </Link>
                            <p>{data.name}</p>
                        </div>
                    </>
                ) : (
                    <h4 className={styles.title}>{data.name}</h4>
                )}
                <div className={styles.info}>
                    <span className={styles.price}>$ {data.price.sale_value}</span>
                    <button className={clsx('link', styles.shop_btn)}>Shop this</button>
                </div>
            </div>
        </>
    )

    return (
        <Link href={`/products/${data.id}`} className={clsx(styles.card, className)} onClick={onClick}>
            {content}
        </Link>
    )
}
