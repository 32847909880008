import React from 'react'
import styles from './CategoryBlock.module.sass'
import { Button } from '@/components/ui/Button'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ProductCard } from '@/components/ProductCard'
import { Pagination } from 'swiper/modules'
import { BannerProductT } from '@/shared/types/BannerT'
import clsx from 'clsx'
import Link from 'next/link'

interface CategoryBlockProps {
    departmentId: string
    products: BannerProductT[]
    banner: string
    bannerTitle: string
    categoryBtnText: string
    bottomBanner?: string
    isFull?: boolean
}

export const CategoryBlock: React.FC<CategoryBlockProps> = ({
    departmentId,
    products,
    banner,
    bannerTitle,
    categoryBtnText,
    bottomBanner,
    isFull,
}) => {
    return (
        <div className={styles.category_block_wrap}>
            <div className={clsx(styles.category_block, 'container')}>
                <div className={styles.banner} style={{ backgroundImage: `url(${banner})` }}>
                    <div className={styles.banner_content}>
                        <Link href={`/departments/${departmentId}`} className={styles.banner_title}>
                            {bannerTitle}
                        </Link>
                        <Button color="blur" href={`/departments/${departmentId}`}>
                            {categoryBtnText}
                        </Button>
                    </div>
                </div>

                <Swiper
                    modules={[Pagination]}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    className={styles.products_slider}
                    wrapperClass={styles.products_slider_wrapper}
                    slidesPerView={'auto'}
                    spaceBetween={30}
                    loop={true}
                >
                    {products?.map((product) => (
                        <SwiperSlide key={product.product_id} className={styles.slide}>
                            <ProductCard
                                key={product.product_id}
                                data={{
                                    name: product.product_name,
                                    id: product.product_id,
                                    main_image: {
                                        original_url: product.image_url,
                                    },
                                    price: { sale_value: product.price },
                                }}
                                className={styles.product}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {bottomBanner && (
                <div className={clsx(styles.category_block_banner, isFull ? styles.full : 'contaienr')}>
                    <img src={bottomBanner} />
                </div>
            )}
        </div>
    )
}
