'use client'
import { useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { AuthService } from '@/shared/services'
import { accessTokenName, refreshTokenName } from '@/shared/api'
import { useAuth } from '@/shared/providers/AuthProvider'
import Cookies from 'js-cookie'

const OAuthHandler = () => {
    const router = useRouter()
    const { setUser } = useAuth()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const refreshToken = urlParams.get('refresh')

        if (refreshToken) {
            AuthService.rebuildAccessToken(refreshToken)
                .then((data) => {
                    Cookies.set(accessTokenName, data.data.accessToken, { secure: true })
                    Cookies.set(refreshTokenName, data.data.refreshToken, { secure: true })

                    AuthService.getUserMe().then((user) => {
                        setUser(user.data)
                    })

                    const newUrl = window.location.pathname
                    window.history.replaceState({}, document.title, newUrl)

                    router.push('/')
                    // location.reload()
                })
                .catch((error) => {
                    console.error('Ошибка обновления токена:', error)
                    router.push('/auth/login')
                })
        }
    }, [router, setUser])

    return null
}

export default OAuthHandler
