import React from 'react'
import styles from './Logo.module.sass'
import clsx from 'clsx'
import Link from 'next/link'

interface LogoProps {
    className?: string
    href?: string
    color?: string
    size?: number
}

const Logo: React.FC<LogoProps> = ({ className, href = '/', color, size }) => {
    return (
        <Link
            href={href}
            className={clsx(className, styles.logo)}
            style={{ color, fontSize: size }}
        >
            for you
        </Link>
    )
}

export default Logo
