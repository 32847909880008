import axios from 'axios'
import Cookies from 'js-cookie'
import AuthService from '@/shared/services/AuthService'

export const accessTokenName = '4you_access_token'
export const refreshTokenName = '4you_refresh_token'

export const API_URL = process.env.NEXT_PUBLIC_API_BASE_URL

const API = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

API.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get(accessTokenName)
        if (accessToken && config.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
    },
    (error) => Promise.reject(error),
)

API.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            const refreshToken = Cookies.get(refreshTokenName)
            if (refreshToken) {
                try {
                    const newAccessToken = await AuthService.rebuildAccessToken(refreshToken)
                    Cookies.set(accessTokenName, newAccessToken.data.accessToken, { secure: true })
                    Cookies.set(refreshTokenName, newAccessToken.data.refreshToken, { secure: true })

                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
                    return API(originalRequest)
                } catch (refreshError) {
                    console.error('Ошибка обновления токена:', refreshError)
                }
            }
            Cookies.remove(accessTokenName)
            Cookies.remove(refreshTokenName)
        }

        return Promise.reject(error)
    },
)

export default API
