import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"100\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Gloria_Hallelujah\",\"arguments\":[{\"variable\":\"--logo-font\",\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"gloria\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/Logo/Logo.module.sass");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CombinedProvider"] */ "/app/src/shared/providers/CombinedProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/app/src/shared/styles/theme.sass");
;
import(/* webpackMode: "eager" */ "/app/src/shared/styles/globals.sass");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/Footer/Footer.module.sass");
