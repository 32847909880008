import React from "react";
import styles from "./Backdrop.module.sass";
import clsx from "clsx";

interface BackdropProps {
    isOpen: boolean;
    onClose: () => void;
}
const Backdrop: React.FC<BackdropProps> = ({ isOpen, onClose }) => {
    return <div className={clsx(styles.backdrop, isOpen && styles.active)} onClick={onClose}></div>;
};

export default Backdrop;
