import React, { useState, useRef } from 'react'
import styles from './Accordion.module.sass'
import clsx from 'clsx'
export interface AccordionProps {
    title?: React.ReactNode
    children?: React.ReactNode
    className?: string
    titleClassName?: string
    customIsOpen?: boolean
    customToggleAccordion?: () => void
}

export const Accordion: React.FC<AccordionProps> = ({
    title,
    children,
    className,
    titleClassName,
    customIsOpen,
    customToggleAccordion,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const contentRef = useRef<HTMLDivElement>(null)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            className={clsx(
                styles.accordion,
                (customIsOpen !== undefined ? customIsOpen : isOpen) && styles.active,
                className,
            )}
        >
            <div
                className={clsx(styles.accordion_title, titleClassName)}
                onClick={customToggleAccordion !== undefined ? customToggleAccordion : toggleAccordion}
            >
                <h4>{title}</h4>
                <i className="icon-chevron-down"></i>
            </div>
            <div
                className={styles.accordion_content}
                style={{
                    maxHeight: (customIsOpen !== undefined ? customIsOpen : isOpen)
                        ? contentRef.current?.scrollHeight
                        : 0,
                    transition: 'max-height 0.3s ease',
                }}
                ref={contentRef}
            >
                {children}
            </div>
        </div>
    )
}
