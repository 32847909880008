import API from '@/shared/api'
import { AxiosResponse } from 'axios'
import { UserT } from '../types/UserT'

interface LoginDataT {
    email: string
    password: string
}

interface RegisterDataT {
    first_name: string
    last_name: string
    email: string
    password: string
}

interface PasswordRecoveryDataT {
    code: string
    newPassword: string
}

interface UserDataT {
    first_name?: string
    last_name?: string
    email?: string
    phone?: string
    iin?: string
    bin?: string
    bik?: string
    gender?: string
}

interface PasswordChangeDataT {
    oldPassword: string
    newPassword: string
}

const AuthService = {
    loginUser: (data: LoginDataT) => API.post('/auth-password/login', data),
    registerData: (data: RegisterDataT) => API.post('/auth-password/registration', data),
    getUserMe: (): Promise<AxiosResponse<UserT>> => API.get('/auth/me'),
    rebuildAccessToken: (refresh: string) => API.post('/auth/rebuild-access-token', { refreshToken: refresh }),
    sendOtp: (email: string) => API.post('/auth-password/forgot', { email }),
    updatePasswordByCode: (data: PasswordRecoveryDataT) => API.post('/auth-password/update-by-code', data),
    updateUser: (data: UserDataT, id: number) => API.put(`/users/${id}`, data),
    updatePasswordByOldPassword: (data: PasswordChangeDataT) => API.patch('/auth-password/update', data),
    addToWishList: (id: number) => API.post('/wishlist', { product: id }),
    removeFromWishList: (id: number) => API.delete(`/wishlist/${id}`),
    getWishList: () => API.get('/wishlist'),
    getAddress: () => API.get('/address'),
}

export default AuthService
