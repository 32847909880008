import API from '@/shared/api'

type UpdateProductsDataT = {
    stock: number
    count: number
}

const CartService = {
    getProducts: () => API.get('/cart-products/my'),
    addProduct: (data: UpdateProductsDataT) => API.post('/cart-products', data),
    removeProduct: (id: number) => API.delete(`/cart-products/${id}`),
    orderSummary: () => API.get('/order/summary'),
    updateProductsCount: (count: number, id: number) => API.patch(`/cart-products/${id}`, { count }),
}

export default CartService
