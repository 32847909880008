import clsx from 'clsx'
import styles from './Modal.module.sass'

interface ModalProps {
    isOpen: boolean
    title?: string
    children?: React.ReactNode
    controls?: React.ReactNode
    onClose?: () => void
}
export const Modal: React.FC<ModalProps> = ({ isOpen, title, children, controls, onClose }) => {
    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget && onClose) {
            onClose()
        }
    }

    return (
        <div className={clsx(styles.modal, isOpen && styles.active)} onClick={handleBackdropClick}>
            <div className={styles.modal_inner}>
                <div className={styles.modal_header}>
                    {title && <h4 className={styles.title}>{title}</h4>}
                    <button className={styles.modal_close} onClick={onClose}>
                        <i className="icon-cross-small"></i>
                    </button>
                </div>

                <div className={styles.modal_content}>{children}</div>

                <div className={styles.modal_footer}>{controls}</div>
            </div>
        </div>
    )
}
