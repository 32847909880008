import React from 'react'
import styles from './NavCart.module.sass'
import { Button } from '@/components/ui/Button'
import { useCart } from '@/shared/providers/CartProvider'
import Link from 'next/link'
import { useAuth } from '@/shared/providers/AuthProvider'

interface NavCartProps {
    onClose?: () => void
}

export const NavCartProducts = () => {
    const { products, updateProducts, setIsCartOpen } = useCart()
    const { user } = useAuth()

    const handleRemoveClick = (e: React.MouseEvent<HTMLSpanElement>, cartId: number) => {
        e.stopPropagation()
        e.preventDefault()
        updateProducts({
            cart_id: cartId,
            stock: 0,
        })
    }

    return (
        <div className={styles.cart_content}>
            {user ? (
                products.length === 0 ? (
                    <h4 className={styles.empty_text}>Cart is empty</h4>
                ) : (
                    products.map((product) => (
                        <div className={styles.cart_content_item} key={product.id}>
                            <Link href={`/products/${product.stock.product.id}`} className={styles.image_wrap}>
                                <img
                                    loading="lazy"
                                    src={product.stock.product.main_image?.original_url}
                                    width={158}
                                    height={158}
                                    alt="product"
                                    className={styles.image}
                                />
                            </Link>

                            <div className={styles.info}>
                                <div className={styles.title_wrap}>
                                    <Link
                                        href={`/products?brandIds=${product.stock.product.brand?.id}`}
                                        className={styles.title}
                                        onClick={() => setIsCartOpen(false)}
                                    >
                                        {product.stock.product.brand?.name}
                                    </Link>
                                    <Link href={`/products/${product.stock.product.id}`} className={styles.title_link}>
                                        <p className={styles.desc}>{product.stock.product.name}</p>
                                    </Link>
                                    <span className={styles.price}>$ {product.stock.product.price.sale_value}</span>
                                </div>

                                <p className={styles.info_item}>Size: {product.stock.size.name}</p>

                                <p className={styles.info_item}>Quantity: {product.count}</p>
                            </div>

                            <span className={styles.close} onClick={(e) => handleRemoveClick(e, product.id)}>
                                <i className="icon-cross-small"></i>
                            </span>
                        </div>
                    ))
                )
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <h4 className={styles.empty_text}>You must be logged in to use the shopping cart</h4>
                    <Button href="/cart" color="primary" onClick={() => setIsCartOpen(false)}>
                        Sign in
                    </Button>
                </div>
            )}
        </div>
    )
}

export const NavCartControls = () => {
    return (
        <div className={styles.cart_controls}>
            <Button href="/checkout" variant="contained" color="primary">
                checkout
            </Button>
            <Button href="/cart" variant="outlined" color="primary">
                view shopping bag
            </Button>
        </div>
    )
}

export const NavCart: React.FC<NavCartProps> = ({ onClose }) => {
    const { user } = useAuth()
    return (
        <div className={styles.cart}>
            <div className={styles.cart_header}>
                <h4 className={styles.cart_header_title}>Adding to shopping bag</h4>
                <span className={styles.cart_close} onClick={onClose}>
                    <i className="icon-cross-small"></i>
                </span>
            </div>

            <NavCartProducts />

            {user && <NavCartControls />}
        </div>
    )
}
