export const SidenavUserLinks = [
    {
        label: 'My account',
        href: '/user/personal-information',
    },
    {
        label: 'Sales',
        href: '/user/sales',
    },
    {
        label: 'Order-made products',
        href: '/user/order-made-products',
    },
    {
        label: 'Settings',
        href: '/user/settings',
    },
    {
        label: 'Orders and retutrns',
        href: '/user/orders-and-returns',
    },
]
