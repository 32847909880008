'use client'
import { ReactNode, SetStateAction } from 'react'
import { useContext, createContext, useState, useEffect } from 'react'
import CartService from '../services/CartService'
import { ProductSizeT, ProductT } from '../types/ProductT'
import { useAuth } from './AuthProvider'
import { useSnackbar } from 'notistack'

interface CartProviderProps {
    children: ReactNode
}

const CartContext = createContext<{
    products: CartProductT[]
    isCartOpen: boolean
    isLoading: boolean
    setIsCartOpen: React.Dispatch<SetStateAction<boolean>>
    setIsLoading: React.Dispatch<SetStateAction<boolean>>
    updateProducts: (data: ProductInfoT) => void
    updateProductCount: (count: number, id: number) => void
}>({
    products: [],
    isCartOpen: false,
    isLoading: false,
    setIsCartOpen: () => {},
    setIsLoading: () => {},
    updateProducts: () => {},
    updateProductCount: () => {},
})

type ProductInfoT = {
    stock: number
    count?: number
    cart_id?: number
}

interface CartProductT {
    id: number
    created_at: string
    updated_at: string
    count: number
    stock: {
        id: number
        vin: any
        amount: number
        created_at: string
        updated_at: string
        product: ProductT
        size: ProductSizeT
    }
}

const CartProvider = ({ children }: CartProviderProps) => {
    const [isCartOpen, setIsCartOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<CartProductT[]>([])
    const { user } = useAuth()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (user) {
            fetchProducts()
        }
    }, [user])

    const fetchProducts = async () => {
        if (user) {
            setIsLoading(true)
            try {
                const response = await CartService.getProducts()
                if (response.data) {
                    setProducts(response.data.items)
                }
            } catch (error) {
                if (typeof error === 'string') {
                    throw new Error(error)
                } else {
                    console.error(error)
                }
            } finally {
                setIsLoading(false)
            }
        }
    }

    const updateProducts = (productInfo: ProductInfoT) => {
        if (user) {
            if (products.find((product) => product.id === productInfo.cart_id)) {
                if (productInfo.cart_id) {
                    setIsLoading(true)
                    CartService.removeProduct(productInfo.cart_id)
                        .then(() => {
                            fetchProducts()
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            } else {
                if (productInfo.count && productInfo.stock) {
                    setIsLoading(true)
                    CartService.addProduct({
                        ...productInfo,
                        count: productInfo.count,
                    })
                        .then(() => {
                            fetchProducts()
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            }
        } else {
            enqueueSnackbar('Вы не авторизованы', { variant: 'error' })
        }
    }

    const updateProductCount = async (count: number, id: number) => {
        if (products.find((product) => product.id === id)) {
            setIsLoading(true)
            try {
                const response = await CartService.updateProductsCount(count, id)
                if (response.data) {
                    setProducts((prev) => {
                        const prevCopy = [...prev]
                        const index = products.findIndex((product) => product.id === id)
                        if (index) {
                            prevCopy.splice(index, 1, { ...prevCopy[index], count: response.data.count })
                        }
                        return prevCopy
                    })
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    }

    return (
        <CartContext.Provider
            value={{ products, isCartOpen, isLoading, setIsCartOpen, setIsLoading, updateProducts, updateProductCount }}
        >
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider

export const useCart = () => {
    return useContext(CartContext)
}
