import React from 'react'
import styles from './Button.module.sass'
import clsx from 'clsx'
import Link from 'next/link'

interface ButtonProps {
    variant?: 'contained' | 'outlined' | 'link' | 'text'
    color?: 'primary' | 'secondary' | 'white' | 'blur'
    children: React.ReactNode
    href?: string
    loading?: boolean
    className?: string
    type?: 'submit' | 'reset' | 'button'
    disabled?: boolean
    onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({
    variant = 'contained',
    color = 'primary',
    children,
    href,
    loading,
    type = 'button',
    className,
    disabled,
    onClick,
}) => {
    const classNameString = clsx(styles.button, styles[variant], styles[color], variant === 'link' && 'link', className)

    if (href) {
        return (
            <Link href={href} className={classNameString} onClick={onClick}>
                <p>{children}</p>
            </Link>
        )
    }
    return (
        <button type={type} className={classNameString} disabled={disabled || loading} onClick={onClick}>
            {loading ? <p>Загрузка</p> : variant === 'link' ? children : <p>{children}</p>}
        </button>
    )
}
