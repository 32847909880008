import React, { useEffect, useRef, useState } from 'react'
import styles from './Menu.module.sass'
import clsx from 'clsx'

interface MenuProps {
    isOpen?: boolean
    titleContent: React.ReactNode
    children: React.ReactNode
    className?: string
    onMenuToggle?: () => void
    onClose?: () => void
}

export const Menu: React.FC<MenuProps> = ({ isOpen, titleContent, children, className, onMenuToggle, onClose }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const menuRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef && menuRef.current && !menuRef.current.contains(event.target as Node)) {
            if (onClose) {
                onClose()
            } else {
                setIsMenuOpen(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div className={clsx(styles.menu, className)} ref={menuRef}>
            <div
                className={styles.menu_title}
                onClick={() => (onMenuToggle ? onMenuToggle() : setIsMenuOpen(!isMenuOpen))}
            >
                {titleContent}
            </div>
            <div
                className={clsx(styles.menu_content, isOpen ? isOpen && styles.active : isMenuOpen && styles.active)}
                onClick={() => setIsMenuOpen(false)}
            >
                {children}
            </div>
        </div>
    )
}
