import React from 'react'
import styles from './Navbar.module.sass'
import { Accordion } from '@/components/ui/Accordion'
import Image from 'next/image'

export const SidenavFooter = () => {
    return (
        <div className={styles.sidenav_footer}>
            <Accordion
                className={styles.accordion}
                titleClassName={styles.accordion_title}
                title={
                    <div className={styles.accordion_title_content}>
                        <img
                            loading="lazy"
                            src="/assets/img/flags/uk.png"
                            width={128}
                            height={128}
                            alt="uk flag"
                            className={styles.flag}
                        />
                        English
                    </div>
                }
            >
                <div className={styles.accordion_content}>
                    <h2>Test</h2>
                </div>
            </Accordion>
            <Accordion
                className={styles.accordion}
                titleClassName={styles.accordion_title}
                title={
                    <div className={styles.accordion_title_content}>
                        <span>($)</span> Currency
                    </div>
                }
            >
                <div className={styles.accordion_content}>
                    <h2>Test</h2>
                </div>
            </Accordion>
        </div>
    )
}
