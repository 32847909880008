import { AxiosResponse } from 'axios'
import API from '@/shared/api'
import { DepartmentT } from '@/shared/types/DepartmentT'
import { ProductT } from '@/shared/types/ProductT'
import { BrandT } from '../types/BrandT'

const ProductsService = {
    getBanners: async (): Promise<AxiosResponse<{ items: any[]; info: { count: number } }>> => API.get('/banner'),
    getBannersWithProducts: async (): Promise<AxiosResponse<{ items: any[]; info: { count: number } }>> =>
        API.get('/banner/with-goods'),
    getDepartments: async (data?: object): Promise<AxiosResponse<{ items: DepartmentT[]; info: { count: number } }>> =>
        API.get('/departments', { params: data }),
    getDepartmentById: async (id: number): Promise<AxiosResponse<DepartmentT>> => API.get(`/departments/${id}`),
    getProducts: async (data?: object): Promise<AxiosResponse<{ items: ProductT[]; info: { count: number } }>> =>
        API.get('/products', {
            params: data,
        }),
    getProductById: async (id: number | string, data?: object): Promise<AxiosResponse<ProductT>> =>
        API.get('/products/' + id, { params: data }),
    getBrands: async (data?: object): Promise<AxiosResponse<{ items: BrandT[]; info: { count: number } }>> =>
        API.get('/brands', { params: data }),
    getColors: async (data?: object): Promise<AxiosResponse<{ items: BrandT[]; info: { count: number } }>> =>
        API.get('/color', { params: data }),
    getSizes: async (data?: object): Promise<AxiosResponse<{ items: BrandT[]; info: { count: number } }>> =>
        API.get('/sizes', { params: data }),
    getStores: async (data?: object): Promise<AxiosResponse<{ items: BrandT[]; info: { count: number } }>> =>
        API.get('/stores', { params: data }),
    getMaterials: async (data?: object): Promise<AxiosResponse<{ items: BrandT[]; info: { count: number } }>> =>
        API.get('/materials', { params: data }),
}

export default ProductsService
