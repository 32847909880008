'use client'
import clsx from 'clsx'
import styles from './HomePage.module.sass'
import { Button } from '@/components/ui/Button'
import { CategoryBlock } from '@/widgets/CategoryBlock'

interface HomePageProps {
    banners: any[]
}

const HomePage: React.FC<HomePageProps> = ({ banners }) => {
    console.log(banners)

    return (
        <div className={clsx(styles.page)}>
            <div className={clsx(styles.banner, 'container')}>
                <div className={styles.sticky_container}>
                    <div className={styles.banner_content}>
                        <h4 className={styles.title}>Pre-Fall 2024</h4>
                        <Button color="white" href="/departments/2">
                            explore women&apos;s collection
                        </Button>
                    </div>
                </div>
            </div>

            <div className={styles.categories}>
                <div className={styles.categories_list}>
                    <CategoryBlock
                        departmentId={'1'}
                        products={[]}
                        banner="/assets/img/man_category_img.png"
                        bannerTitle="MEN"
                        categoryBtnText="explore men's collection"
                    />
                    <CategoryBlock
                        departmentId={'2'}
                        products={[]}
                        banner="/assets/img/women_category_img.png"
                        bannerTitle="WOMEN"
                        categoryBtnText="explore men's collection"
                    />
                    <CategoryBlock
                        departmentId={'3'}
                        products={[]}
                        banner="/assets/img/kids_category_img.png"
                        bannerTitle="FOR KIDS"
                        categoryBtnText="explore men's collection"
                        isFull
                        bottomBanner="/assets/img/kids_category_img.png"
                    />
                    {/* {banners.map((category) => (
                        <CategoryBlock
                            key={category.id}
                            departmentId={category.department_id}
                            products={category.products}
                            banner={category.imageUrl}
                            bannerTitle={category.title}
                            categoryBtnText={`Explore ${category.title} collection`}
                        />
                    ))} */}
                </div>
            </div>
        </div>
    )
}

export default HomePage
