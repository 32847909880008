import API from '@/shared/api'

interface CheckoutData {
    address: number
    delivery: number
    promocode?: number
    is_active?: boolean
}

const PaymentService = {
    getCountries: (params: any) => API.get('/countries', { params }),
    orderCheckout: async (data: CheckoutData) => API.post('/order/checkout', { is_active: true, ...data }),
}

export default PaymentService
