import React from 'react'
import styles from './Navbar.module.sass'
import clsx from 'clsx'

interface NavHamburgerProps {
    sidenavToggle: () => void
    sidenavOpen: boolean
}

export const NavHamburger: React.FC<NavHamburgerProps> = ({ sidenavToggle, sidenavOpen }) => {
    return (
        <button className={styles.hamburger_wrap} onClick={sidenavToggle}>
            {/* <i className="icon-menu"></i> */}
            <div className={clsx(styles.hamburger, sidenavOpen && styles.active)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            MENU
        </button>
    )
}
