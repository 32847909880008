'use client'
import React, { useState } from 'react'
import AuthProvider from './AuthProvider'
import OAuthHandler from '@/widgets/auth/OAuthHandler'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import NextTopLoader from 'nextjs-toploader'
import CartProvider from './CartProvider'
import { SnackbarProvider } from 'notistack'

export const CombinedProvider = ({ children }: { children: React.ReactNode }) => {
    const [queryClient] = useState(() => new QueryClient())
    return (
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                className="snackbar"
            >
                <NextTopLoader />
                <AuthProvider>
                    <CartProvider>
                        <OAuthHandler />
                        {children}
                    </CartProvider>
                </AuthProvider>
            </SnackbarProvider>
        </QueryClientProvider>
    )
}
