'use client'
import React, { useState, useEffect } from 'react'
import styles from './Navbar.module.sass'
import Logo from '../Logo'
import clsx from 'clsx'
import { usePathname, useRouter } from 'next/navigation'
import { Menu } from '@/components/ui/Menu'
import { NavCart, NavCartControls, NavCartProducts } from '../NavCart'
import { SidenavFooter } from './SidenavFooter'
import Backdrop from '@/components/ui/Backdrop'
import { Button } from '@/components/ui/Button'
import { NavHamburger } from './Hamburger'
import { useAuth } from '@/shared/providers/AuthProvider'
import { Modal } from '../ui/Modal'
import { useCart } from '@/shared/providers/CartProvider'
import { SidenavUserLinks } from './SidenavUserLinks'
import { ProductsService } from '@/shared/services'
import { DepartmentT } from '@/shared/types/DepartmentT'
import Link from 'next/link'
import { useQuery } from '@tanstack/react-query'

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [sidenavOpen, setSidenavOpen] = useState<string>('')
    const [isAnimated, setIsAnimated] = useState<boolean>(true)
    const [history, setHistory] = useState<DepartmentT[]>([])
    const { isCartOpen, setIsCartOpen } = useCart()
    const { user } = useAuth()
    const pathname = usePathname()

    const sidenavClose = () => setSidenavOpen('')
    const sidenavLeftToggle = () => setSidenavOpen((prev) => (prev.length > 0 ? '' : 'left'))
    const sidenavRightToggle = () => setSidenavOpen((prev) => (prev.length > 0 ? '' : 'right'))
    const router = useRouter()

    const { data: subDepartmentsCheck } = useQuery({
        queryKey: ['checkSubDepartments', history[0]?.id],
        queryFn: async () => {
            if (!history[0]?.id) return null
            const response = await ProductsService.getDepartments({
                parentId: history[0].id,
                type: 1,
            })
            return response.data.items.length > 0
        },
        enabled: history.length === 1,
    })

    const {
        data: currentLevelData,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: ['menuLevel', history.length],
        queryFn: async () => {
            const currentStep = history[history.length - 1]
            const parentId = currentStep?.id

            if (!parentId) {
                return ProductsService.getDepartments({ type: 0 })
            }

            const departmentType = currentStep.type + 1 === 1 ? (subDepartmentsCheck ? 1 : 2) : currentStep.type + 1

            const response = await ProductsService.getDepartments({ parentId, type: departmentType })
            if (response.data.items.length === 0) {
                const url = generateUrl()
                if (url) {
                    router.push(url)
                    setSidenavOpen('')
                }
            }
            return response
        },
    })

    const handleDepartmentClick = (department) => {
        setHistory((prev: any) => [
            ...prev,
            {
                id: department.id,
                name: department.name,
                level: prev.length,
                type: department.type,
            },
        ])
    }

    const handleBackClick = () => {
        setHistory((prev) => prev.slice(0, -1))
    }

    const generateUrl = () => {
        if (history.length === 0) return ''

        const params = new URLSearchParams()
        params.append('departmentId', history[0].id.toString())

        const departmentsNumbers = {
            1: 'subDepartment',
            2: 'category',
            3: 'subCategory',
            4: 'innerCategory',
            5: 'productType',
        }

        history.forEach((historyItem, index) => {
            if (index === 0) return
            if (index === 1) {
                if (subDepartmentsCheck) {
                    params.append(departmentsNumbers[1], historyItem.id.toString())
                } else {
                    params.append(departmentsNumbers[2], historyItem.id.toString())
                }
            } else {
                params.append(departmentsNumbers[index + 1], historyItem.id.toString())
            }
        })

        // if (history.length > 1) {
        //     if (subDepartmentsCheck) {
        //         params.append('subdepartment', history[1].id.toString())
        //         if (history.length > 2) {
        //             params.append('category', history[2].id.toString())
        //             if (history.length > 3) {
        //                 params.append('subCategory', history[3].id.toString())
        //             }
        //         }
        //     } else {
        //         params.append('category', history[1].id.toString())
        //         if (history.length > 2) {
        //             params.append('subCategory', history[2].id.toString())
        //         }
        //     }
        // }

        //@ts-ignore
        if (history[history.length - 1].type === 0) {
            return `/departments/${history[0].id}`
        } else if (history[history.length - 1].type === 1) {
            return `/departments/${history[0].id}/${history[1].id}`
        } else {
            return `/products?${params.toString()}`
        }
    }

    useEffect(() => {
        if (pathname === '/' || pathname.startsWith('/departments') || !pathname.includes('category')) {
            const handleScroll = () => {
                if (window.scrollY === 0) {
                    setIsAnimated(true)
                } else {
                    setIsAnimated(false)
                }
            }
            handleScroll()
            window.addEventListener('scroll', handleScroll)
            return () => window.removeEventListener('scroll', handleScroll)
        }
        console.log(pathname)
        setIsCartOpen(false)
    }, [pathname])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsMobile(window.innerWidth <= 768)
        }
    }, [])

    // useEffect(() => {
    //     if (currentLevelData?.data.items.length === 0) {
    //         router.push(generateUrl())
    //         setSidenavOpen('')
    //     }
    // }, [currentLevelData])

    return (
        <>
            <header
                className={clsx(
                    styles.navbar,
                    (pathname === '/' || (pathname.startsWith('/departments') && !pathname.includes('category'))) &&
                        isAnimated &&
                        !sidenavOpen &&
                        styles.animated,
                    pathname === '/' && isAnimated && !sidenavOpen && styles.logo_animation,
                    sidenavOpen && styles.active,
                )}
            >
                <div className={clsx(styles.navbar_content, 'container')}>
                    <NavHamburger sidenavOpen={sidenavOpen === 'left'} sidenavToggle={sidenavLeftToggle} />
                    <Logo className={styles.logo} href="/" />

                    <nav className={styles.navbar_right}>
                        {user && (
                            <button onClick={() => sidenavRightToggle()} className={clsx(styles.navbar_right_item)}>
                                <i className="icon-user"></i>
                            </button>
                        )}
                        {!isMobile ? (
                            <Menu
                                isOpen={isCartOpen}
                                onMenuToggle={() => setIsCartOpen(!isCartOpen)}
                                onClose={() => setIsCartOpen(false)}
                                titleContent={
                                    <a
                                        className={clsx(
                                            styles.navbar_right_item,
                                            sidenavOpen === 'right' && styles.disabled,
                                        )}
                                    >
                                        <i className="icon-cart"></i>
                                    </a>
                                }
                            >
                                <NavCart onClose={() => setIsCartOpen(false)} />
                            </Menu>
                        ) : (
                            <button
                                onClick={() => setIsCartOpen(true)}
                                className={clsx(styles.navbar_right_item, sidenavOpen === 'right' && styles.disabled)}
                            >
                                <i className="icon-cart"></i>
                            </button>
                        )}

                        <a
                            href="#"
                            className={clsx(styles.navbar_right_item, sidenavOpen === 'right' && styles.disabled)}
                        >
                            <i className="icon-search"></i>
                        </a>
                    </nav>
                </div>
            </header>

            <div className={clsx(styles.sidenav, styles.left, sidenavOpen === 'left' && styles.active)}>
                <div className={styles.sidenav_content}>
                    <NavHamburger sidenavOpen={sidenavOpen === 'left'} sidenavToggle={sidenavLeftToggle} />
                    {!user && (
                        <div className={styles.sidenav_auth_controls}>
                            <Button href="/auth/login" onClick={sidenavClose}>
                                Sign in
                            </Button>
                            <Button href="/auth/register" variant="outlined" onClick={sidenavClose}>
                                Register
                            </Button>
                        </div>
                    )}

                    <div className={styles.sidenav_departments}>
                        {history.length > 0 && (
                            <button onClick={handleBackClick} className={styles.sidenav_departments_back}>
                                <i className="icon-chevron-left"></i>
                                <p className="link">Back</p>
                            </button>
                        )}
                        {history.length > 0 && (
                            <Link
                                href={generateUrl()}
                                className={styles.sidenav_departments_title}
                                onClick={sidenavClose}
                            >
                                {history[history.length - 1].name}
                            </Link>
                        )}
                        <div className={styles.sidenav_departments_content}>
                            {isLoading || isFetching ? (
                                <h4>Loading</h4>
                            ) : (
                                currentLevelData?.data.items
                                    .sort((a, b) => a.id - b.id)
                                    .map((department) =>
                                        department.is_active ? (
                                            <button
                                                key={department.id}
                                                className={styles.sidenav_link}
                                                onClick={() => handleDepartmentClick(department)}
                                            >
                                                <p>{department.name}</p>
                                                <i className="icon-chevron-right"></i>
                                            </button>
                                        ) : null,
                                    )
                            )}
                        </div>
                    </div>
                </div>
                <SidenavFooter />
            </div>

            <div className={clsx(styles.sidenav, styles.right, sidenavOpen === 'right' && styles.active)}>
                <div className={styles.sidenav_content}>
                    <div className={styles.sidenav_departments}>
                        {SidenavUserLinks.map((link) => (
                            <Link
                                key={link.href}
                                href={link.href}
                                className={styles.sidenav_link}
                                onClick={sidenavClose}
                            >
                                <p>{link.label}</p>
                                <i className="icon-chevron-right"></i>
                            </Link>
                        ))}
                    </div>
                </div>
                <SidenavFooter />
            </div>

            {isMobile && (
                <Modal
                    isOpen={isCartOpen}
                    title="Adding to shopping bag"
                    controls={<NavCartControls />}
                    onClose={() => setIsCartOpen(false)}
                >
                    <NavCartProducts />
                </Modal>
            )}

            <Backdrop isOpen={sidenavOpen.length > 0} onClose={() => setSidenavOpen('')} />
        </>
    )
}

export default Navbar
