'use client'
import Cookies from 'js-cookie'
import { ReactNode } from 'react'
import { UserT } from '../types/UserT'
import { AuthService } from '../services'
import { accessTokenName, refreshTokenName } from '../api'
import { useContext, createContext, useState, useEffect } from 'react'
import { ProductT } from '../types/ProductT'
import { enqueueSnackbar } from 'notistack'

interface AuthProviderProps {
    children: ReactNode
}

const AuthContext = createContext<{
    user: UserT | null
    favouriteProducts: ProductT[]
    setUser: (user: UserT | null) => void
    addOrRemoveFromWishlist: (id: number) => void
    checkIsFavourite: (id: number) => boolean
    logout: () => void
}>({
    user: null,
    favouriteProducts: [],
    setUser: () => {},
    addOrRemoveFromWishlist: () => {},
    checkIsFavourite: () => false,
    logout: function (): void {
        throw new Error('Function not implemented.')
    },
})

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<UserT | null>(null)
    const [favouriteProducts, setFavouriteProducts] = useState<any>([])
    const [isFavouriteLoading, setIsFavouriteLoading] = useState<boolean>(false)

    const clearTokens = () => {
        Cookies.remove(accessTokenName)
        Cookies.remove(refreshTokenName)
        setUser(null)
    }

    const checkIsFavourite = (id: number): boolean => {
        return favouriteProducts.find((product) => product.id === id) ? true : false
    }

    useEffect(() => {
        const initializeAuth = async () => {
            const accessToken = Cookies.get(accessTokenName)
            const refreshToken = Cookies.get(refreshTokenName)

            if (accessToken) {
                try {
                    const { data } = await AuthService.getUserMe()
                    setUser(data)
                } catch (error) {
                    console.error('Ошибка при запросе пользователя:', error)
                    if (refreshToken) {
                        await refreshAccessToken(refreshToken)
                    }
                }
            } else if (refreshToken) {
                await refreshAccessToken(refreshToken)
            }
        }

        const refreshAccessToken = async (refreshToken: string) => {
            try {
                const { data } = await AuthService.rebuildAccessToken(refreshToken)

                Cookies.set(accessTokenName, data.accessToken, { secure: true })
                const { data: userData } = await AuthService.getUserMe()
                setUser(userData)
            } catch (error) {
                enqueueSnackbar('Ошибка при обновлении токена', { variant: 'error' })
                console.error(error)
                clearTokens()
            }
        }

        initializeAuth()
    }, [])

    useEffect(() => {
        fetchWishlist()
    }, [user])

    const fetchWishlist = () => {
        if (user) {
            AuthService.getWishList().then((resp) => {
                setFavouriteProducts(resp.data)
            })
        }
    }

    const logout = () => {
        clearTokens()
        location.pathname = '/'
        location.reload()
    }

    const addOrRemoveFromWishlist = async (id: number) => {
        if (user) {
            if (!isFavouriteLoading) {
                setIsFavouriteLoading(true)
                const isFavourite = checkIsFavourite(id)
                try {
                    if (isFavourite) {
                        const response = await AuthService.removeFromWishList(id)
                        if (response.data) {
                            fetchWishlist()
                            enqueueSnackbar(`Товар "${id}" удален из избранного`, { variant: 'success' })
                        }
                    } else {
                        const response = await AuthService.addToWishList(id)
                        if (response.data) {
                            fetchWishlist()
                            enqueueSnackbar(`Товар "${id}" добавлен в избранное`, { variant: 'success' })
                        }
                    }
                } catch (error) {
                    enqueueSnackbar('Ошибка при добавлении в избранное', { variant: 'error' })
                    console.error(error)
                } finally {
                    setIsFavouriteLoading(false)
                }
            } else {
                enqueueSnackbar('Подождите пока завершится предыдущее действие', { variant: 'info' })
            }
        } else {
            enqueueSnackbar('Чтобы добавить товар в избранное, требуется авторизация.', { variant: 'warning' })
        }
    }

    return (
        <AuthContext.Provider
            value={{ user, setUser, logout, addOrRemoveFromWishlist, checkIsFavourite, favouriteProducts }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => {
    return useContext(AuthContext)
}
